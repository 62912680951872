<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>About Us</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1> 
                  <button @click="showSummer = true">Summer Timing</button>
                  <button @click="showSummer = false">Winter Timing</button>
                          
                                 
                  
                  <div class="table" v-if="showSummer">
                                     
                      <table>
                        <tbody>
                        <tr>
                        <td bgcolor="#ECA136"><strong>Reporting</strong></td>
                        <td>: 08.15 AM - 08.35 AM</td>
                        </tr>
                        <tr>
                        <td><strong>SUPW</strong></td>
                        <td>: 08.35 AM - 08.50 AM</td>
                        </tr>
                        <tr>
                        <td><strong>Assembly</strong></td>
                        <td>: 08.50 AM - 09.00 AM</td>
                        </tr>
                        <tr>
                        <td><strong>1st Period</strong></td>
                        <td>: 09.00 AM - 10.00 AM</td>
                        </tr>
                        <tr>
                        <td><strong>2nd Period</strong></td>
                        <td>: 10.00 AM - 11.00 AM</td>
                        </tr>
                        <tr>
                        <td bgcolor="#ECA136"><strong>BREAK</strong></td>
                        <td>: 11.00 AM - 11.20 AM</td>
                        </tr>

                        </tbody>
                        </table>

                        <table><tr>
                        <td><strong>3rd Period</strong></td>
                        <td>: 11.20 AM - 12.20 PM</td>
                        </tr>
                        <tr>
                        <td><strong>4th Period</strong></td>
                        <td>: 12.20 PM - 01.20 PM</td>
                        </tr>
                        <tr>
                        <td bgcolor="#ECA136"><strong>BREAK</strong></td>
                        <td>: 01.20 PM - 02.00 PM</td>
                        </tr>
                        <tr>
                        <td><strong>5th Period</strong></td>
                        <td>: 02.00 PM - 02.50 PM</td>
                        </tr>
                        <tr>
                        <td><strong>6th Period</strong></td>
                        <td>: 02.50 PM - 03.40 PM</td></tr>
                        <tr>
                        <td bgcolor="#ECA136"><strong>School Over</strong></td>
                        <td>: 03.40 -</td>
                        </tr>
                    </table>
                  </div>
   
                <div class="table" v-if="!showSummer">
                    <table>
                    <tbody>
                    <tr>
                    <td bgcolor="#ECA136"><strong>Reporting</strong></td>
                    <td>: 08:45 AM - 9:00 AM</td>
                    </tr>
                    <tr>
                    <td><strong>SUPW</strong></td>
                    <td>: 9:00 AM - 9:15 AM</td>
                    </tr>
                    <tr>
                    <td><strong>Assembly</strong></td>
                    <td>: 9:15 AM - 09.20 AM</td>
                    </tr>
                    <tr>
                    <td><strong>1st Period</strong></td>
                    <td>: 09.20 AM - 10.05 AM</td>
                    </tr>
                    <tr>
                    <td><strong>2nd Period</strong></td>
                    <td>: 10.05 AM - 10.50 AM</td>
                    </tr>
                    <tr>
                    <td bgcolor="#ECA136"><strong>BREAK</strong></td>
                    <td>: 10.50 AM - 11.10 AM</td>
                    </tr>

                    </tbody>
                    </table>

                    <table><tr>
                    <td><strong>3rd Period</strong></td>
                    <td>: 11.10 AM - 11.55 PM</td>
                    </tr>
                    <tr>
                    <td><strong>4th Period</strong></td>
                    <td>: 11.55 AM - 12.40 PM</td>
                    </tr>
                    <tr>
                    <td bgcolor="#ECA136"><strong>BREAK</strong></td>
                    <td>: 12.40 PM - 01.20 PM</td>
                    </tr>
                    <tr>
                    <td><strong>5th Period</strong></td>
                    <td>: 01.20 PM - 02.05 PM</td>
                    </tr>
                    <tr>
                    <td><strong>6th Period</strong></td>
                    <td>: 02.05 PM - 02.50 PM</td></tr>
                    <tr>
                    <td bgcolor="#ECA136"><strong>School Over</strong></td>
                    <td>: 02.50 -</td>
                    </tr>
                    </table>
                    </div>
              </div>
              
          <div class="sidebar">
              <AboutUsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import AboutUsQuickLinks from '../../components/page/AboutUsQuickLinks.vue';
import Footer from "../../components/page/Footer.vue";




export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, AboutUsQuickLinks, Footer },
    setup(){
        const title= ref('School Timing')
        const showSummer = ref(true)

        return {title, showSummer}
    }
    
  
}
</script>

<style scoped>
section .container .page table td{
    padding: 0.5rem;
}
section .container .page .table{
    display: block;
}
section .container .page .table table{
    width: 100%;
}
section .container .page .page-content button{
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    margin-right: 1rem;
    border: none;
    background: var(--secondary);
    color:#f1f1f1;
    cursor: pointer;
}section .container .page .page-content button:hover{
    color: var(--primary);
}

section .container .page .page-content a{
    text-decoration: none;
    padding: 0 1rem;
    color: var(--secondary);
}
section .container .page .page-content a.router-link-exact-active{
    color: var(--primary);
}
@media (min-width:768px) {
    section .container .page .table{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}
}
</style>