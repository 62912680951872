<template>
  <div class="tab">
              <ul>
                  <li class='heading'>About Us</li>
                  <li class="one"><router-link :to = "{name: 'Background'}">Background</router-link></li>
                  <li class="two"><router-link :to = "{name: 'VisionMission'}">Vision &amp; Mission</router-link></li>
                  <li class="three"><router-link :to = "{name: 'Principals'}">Principals</router-link></li>
                  <li class="four"><router-link :to = "{name: 'Organogram'}">Organogram</router-link></li>
                  <li class="five"><router-link :to = "{name: 'Committees'}">Committees</router-link></li>
                  <li class="five"><router-link :to = "{name: 'SchoolTiming'}">School Timing</router-link></li>
              </ul>
          </div>
</template>

<script>
export default {

}
</script>

<style>

</style>